/* eslint-disable @typescript-eslint/ban-types */
import {
  ActionLogRequest,
  ActivateJobSiteRequest,
  AdCampaignInfoRequestDto,
  AdCampaignInfoResponseDto,
  AdCampaignJobSitesResponseDto,
  AdCampaignJobsResponseDto,
  AdCampaignRequestDto,
  AdCampaignResponseDto,
  AddAssessmentGroupDto,
  AdditionalProfileInfoDto,
  AddParticipantsDto,
  AiDisclaimerRequestDto,
  AiDisclaimerResponseDto,
  AllChatMessageRequestDto,
  AllChatMessageResponseDto,
  AllChatMessagescountResponse,
  ApplicantSearchRequest,
  ApplicantSearchResult,
  ApplicantSearchSelectableValues,
  AssessmentDetailRequestDto,
  AssessmentRunDetailDto,
  AssessmentRunDto,
  AssessmentSearchRequestDto,
  AssessmentSearchResponseDto,
  AssignUserRequestDto,
  BenchmarkDataDto,
  BulkMessageRecipientResponseDto,
  BulkMessageRecipientSearchRequestDto,
  BulkMessageRequestDto,
  BulkMessageSearchRequestDto,
  BulkMessageSearchResponseDto,
  CampaignInfoRequestDto,
  CampaignInfoResponseDto,
  CampaignQuestionnaireRequestDto,
  CampaignQuestionnaireTemplateDto,
  CampaignRequestDto,
  CampaignResponseDto,
  CampaignStatusChangeRequest,
  CandidateLanguageDto,
  CandidateSalutationDto,
  CandidateTagCreateAssignGroupRequest,
  CandidateTagCreateRequest,
  CandidateTagDto,
  CandidateTagsUpdateRequest,
  ChangePasswordRequest,
  ChannelTemplateMessageDto,
  ChartDataDto,
  ChatMessage,
  ChatMessageRequest,
  ChatMessageResponse,
  CloneTenantDto,
  ClusterJobsUpdateDto,
  CommentRequest,
  CommentResponse,
  Contact,
  ContactsImportResultDto,
  ContactsImportSearchRequest,
  ConversationReportsResponseDto,
  CopyJobRequest,
  CopyJobResult,
  CopyJobTemplateResult,
  CountriesOptionResponse,
  CreateJobSiteRequest,
  CreateSiteRequest,
  CreateTemplateMessageDto,
  DashboardInterviewsRequest,
  DashboardInterviewsResponse,
  DeactivateTenantDto,
  DefaultJobDescriptionDto,
  DirectAssignUserGroup,
  DirectDisclaimerResponseDto,
  DisclaimerTargetResponse,
  DleteAfterDto,
  ExternalCommentRequest,
  ExternalJobInfoResponseDto,
  ExternalJobSetting,
  ExternalTrackingDto,
  FilterResponseDto,
  FunnelDataDto,
  FunnelReportRequest,
  FunnelReportResponse,
  GetShareInterviewContent,
  GetShareInterviewRequest,
  GetShareInterviewResponse,
  GroupCandidateTagDto,
  GroupDto,
  GroupsResponseDto,
  GroupTagAssessmentsRequestDto,
  GroupTagCandidatesRequestDto,
  GroupTagRecipientsRequestDto,
  GroupTransitionRequest,
  GroupTransitionResponse,
  IJAWInterviewRequest,
  IJobInfoRequest,
  ILabelValue,
  InfoNoteDto,
  InitMessage,
  Interview,
  InterviewActionProtocolResponse,
  InterviewAttachmentResponse,
  InterviewParamsDto,
  JAWApplicationConfig,
  JAWConfig,
  JAWConfigByTenantRequest,
  JAWConfigRequest,
  JAWInterviewRequest,
  JAWPropertyConfig,
  JAWStepTemplate,
  JobCategoryResponse,
  JobClusterItem,
  JobClusterOriginsRequestDto,
  JobClusterRequest,
  JobClusterUrlByGroupRequestDto,
  JobClusterUrlItem,
  JobDashboardRequest,
  JobDashboardResponse,
  JobDashboardSearchRequest,
  JobDashboardSearchResponse,
  JobDashboardStatusChangeRequest,
  JobDashboardStatusChangeResponse,
  JobInfoRequest,
  JobInfoResponse,
  JobListRequest,
  JobListResponse,
  JobOriginRequestDto,
  JobPostingSitesCountDto,
  JobsMainCredentials,
  JobStatusChangeRequest,
  JobStatusChangeResponse,
  JobTemplateInterviewRequest,
  JobTemplateListDto,
  JobTemplateResponseDto,
  JobTemplateTag,
  JobTemplateTagRequest,
  JobUrl,
  JobUrlByGroupRequestDto,
  JobUrlsRequest,
  JobUrlsResponse,
  KleinAnzeigenPostingRequestDto,
  KleinAnzeigenPostingResponseDto,
  LoadInterviewDetailRequest,
  LoadLanguageTemplatesRequest,
  LoadTemplatesRequest,
  LoginRequest,
  MediaMetaDataDto,
  Message,
  MessageCenterContact,
  MessageCenterContactSearchRequestDto,
  MessageCenterContactSearchResponseDto,
  MessageTemplate,
  MessengerChannelDto,
  MetaLeadAuthCodeDto,
  MultipleGroupTransitionRequest,
  NewPasswordRequest,
  NotificationInfoDto,
  OnboardNewTenantRequest,
  OnboardNewTenantResponse,
  OnboardRemoteSystemParamsCheckDto,
  OnboardRemoteSystemParamsRequestDto,
  Origin,
  OriginSelectionResponse,
  PagePermissionDto,
  PieChartResponseDto,
  PrivacyConfirmationRequest,
  PrivacyConfirmationResponse,
  PrivacyConfTextResponseDto,
  PublishedPlatformRequest,
  QuestionnaireDetailDto,
  QuestionnaireSearchItemDto,
  QuestionnaireSearchRequestDto,
  QuestionnaireSearchResponseDto,
  QuestionnaireType,
  RecentApplication,
  RecruiterInterview,
  RecruitMeMessagesResponse,
  RejectInterviewRequest,
  RemoteSystemRequestDto,
  RemoteSystemResponseDto,
  ReportsFilterResponse,
  ReportTopApplicantResponse,
  ResetPasswordRequest,
  Role,
  SalesUserRequest,
  SalesUserResponse,
  SaveJobClusterRequest,
  SelectableJob,
  SelectableJobTemplate,
  SelectableSite,
  SelectableUser,
  SelectAllAssessmentsRequestDto,
  SelectAllContactsAndTagsRequestDto,
  SelectAllRecipientsRequestDto,
  SendChatMessageRequest,
  SetChatTranslationRequest,
  ShareInterview,
  ShareInterviewRequest,
  ShortUrlEntity,
  ShortUrlRequest,
  Site,
  SiteSelectionRequest,
  SiteSelectionResponse,
  StackChartReportResponse,
  StartChatResponseDto,
  StartMessageMediaJobRequest,
  StartWabaSetupRequestDto,
  StepMedia,
  StepTemplateGroup,
  SuperAdminATSLoginDto,
  TagMangementDto,
  Tenant,
  TenantInfo,
  TenantLite,
  TenantLoginInfo,
  TenantMessageInfo,
  TFAVerificationDto,
  TransitionStatusRequest,
  TranslationPropTextDto,
  TranslationTextDto,
  UpdateCandidateName,
  UpdateContactGroupDto,
  UpdateCredentialRequest,
  UpdateInterviewSitesResponseDto,
  UpdateUserStatusRequest,
  User,
  UserSubscribedInterviewDto,
  WipeOutAuthCredentialsDto
} from '@/store/models';
import axios from 'axios';
import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';;

export const pitchYouDataAPi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URI
});

export function setJWT(jwt: string) {
  pitchYouDataAPi.defaults.headers.common.Authorization = 'Bearer ' + jwt;
}

export function clearJWTServer() {
  delete pitchYouDataAPi.defaults.headers.common.Authorization;
}

export async function fetchInterviewDetailServer(
  interviewGuid: string
): Promise<Interview> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/interview/v1/' + interviewGuid)
      .then(response => {
        resolve(response.data as Interview);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('NOT_FOUND ' + interviewGuid));
      });
  });
}

export async function fetchAlternativeGroupsServer(
  interviewGuid: string
): Promise<GroupsResponseDto> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/tenantadmin/v1/alternativeGroups/' + interviewGuid)
      .then(response => {
        resolve(response.data as GroupsResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('ALTERNATIVE_GROUP_LOAD_ERROR'));
      });
  });
}

export async function updateCandidateGroupServer(
  requestObj: GroupTransitionRequest
): Promise<GroupTransitionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/recruiter-interview/v1/group-transition', requestObj)
      .then(response => {
        resolve(response.data as GroupTransitionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          reject(new Error('TOKEN_ERROR'));
        } else {
          reject(new Error('ERROR_OCCURRED'));
        }
      });
  });
}

export async function fetchActionProtocolServer(
  interviewGuid: string
): Promise<InterviewActionProtocolResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/recruiter-interview/v1/actionprotocol/' + interviewGuid)
      .then(response => {
        resolve(response.data as InterviewActionProtocolResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FETCH_ACTION_PROTOCOL_FAILED'));
      });
  });
}

export async function fetchInterviewAttachmentsServer(
  interviewGuid: string
): Promise<InterviewAttachmentResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/recruiter-interview/v1/attachments/' + interviewGuid)
      .then(response => {
        resolve(response.data as InterviewAttachmentResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FETCH_INTERVIEW_ATTACHMENTS'));
      });
  });
}

export async function fetchDashboardInterviewsServer(
  requestObj: DashboardInterviewsRequest
): Promise<DashboardInterviewsResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/dashboard/v1', requestObj)
      .then(response => {
        resolve(response.data as DashboardInterviewsResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          reject(new Error('TOKEN_ERROR'));
        } else {
          reject(new Error('ERROR_OCCURRED'));
        }
      });
  });
}

export async function fetchTenantInfoServer(): Promise<Tenant> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenant/v1')
      .then(response => {
        resolve(response.data as Tenant);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('INVALID_TENANT'));
      });
  });
}

export async function fetchTenantInfoLiteServer(
  languageCode?: string
): Promise<TenantLite> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/tenant/v1-tenant-lite', {
        params: { languageCode: languageCode }
      })
      .then(response => {
        resolve(response.data as TenantLite);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('INVALID_TENANT'));
      });
  });
}

export async function fetchTenantLoginInfoServer(): Promise<TenantLoginInfo> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/tenant/v1-tenant-login')
      .then(response => {
        resolve(response.data as TenantLoginInfo);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('INVALID_TENANT'));
      });
  });
}

export async function fetchTenantMessageInfoServer(): Promise<
  TenantMessageInfo
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenant/v1/messages')
      .then(response => {
        resolve(response.data as TenantMessageInfo);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_TENANT_MESSAGE_DATA_FAILED'));
      });
  });
}

export async function performLoginServer(
  loginRequest: LoginRequest
): Promise<User> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/login/v1', loginRequest)
      .then(response => {
        if (response.data.token) {
          SessionStorage.set('app-token', response.data.token);
        }
        resolve(response.data.user as User);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TFA_CODE_ERROR'));
          }
          if (error.response.status && error.response.status === 401) {
            reject(new Error('AUTHORIZATION_ERROR'));
          }
          reject(error.response);
        } else {
          reject(new Error('LOGIN_FAILED'));
        }
      });
  });
}

export async function submitPrivacyConfirmationServer(
  confirmRequest: PrivacyConfirmationRequest
): Promise<PrivacyConfirmationResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/privacy/v1/confirm', confirmRequest)
      .then(response => {
        resolve(response.data as PrivacyConfirmationResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          reject(error.response);
        } else {
          reject(new Error('PRIVACY_CALL_FAILED'));
        }
      });
  });
}

export async function fetchInterviewRecruiterDetailServer(
  request: LoadInterviewDetailRequest
): Promise<RecruiterInterview> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/recruiter-interview/v1/' + request.interviewGuid, {
        params: { postingjob: request.postingjob }
      })
      .then(response => {
        resolve(response.data as RecruiterInterview);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('NOT_FOUND ' + request.interviewGuid));
      });
  });
}

export async function fetchInterviewRecruiterCommentsServer(
  interviewGuid: string
): Promise<CommentResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/comment/v1/' + interviewGuid)
      .then(response => {
        resolve(response.data as CommentResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FAILED_FETCH_COMMENTS'));
      });
  });
}

export async function saveInterviewRecruiterCommentServer(
  commentRequest: CommentRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/comment/v1', commentRequest)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('FAILED_SAVE_COMMENT'));
      });
  });
}

export async function fetchJobListServer(
  jobListRequest: JobListRequest
): Promise<JobListResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobs/v1', jobListRequest)
      .then(response => {
        resolve(response.data as JobListResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('JOB_LIST_CALL_FAILED'));
        }
      });
  });
}

export async function fetchJobDashboardDetailsServer(
  requestObj: JobDashboardRequest
): Promise<JobDashboardResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobdashboard/v1/' + requestObj.jobSlug, {
        params: {
          language: requestObj.languageCode,
          sites: (requestObj.siteSlugs && requestObj.siteSlugs.join()) || '',
          postingJobs: requestObj.postingJobs,
          filterPostingJobs: requestObj.filterPostingJobs
        }
      })
      .then(response => {
        resolve(response.data as JobDashboardResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_DASHBOARD_SEARCH_FAILED'));
      });
  });
}

export async function getShortUrlTargetServer(
  request: ShortUrlRequest
): Promise<ShortUrlEntity> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/shorturl/v1', request)
      .then(response => {
        resolve(response.data as ShortUrlEntity);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_SHORTURL_TARGET_FAILED'));
      });
  });
}

export async function isDirectDisclaimerForGuidServer(
  guid: string
): Promise<DirectDisclaimerResponseDto> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/shorturl/v1/directdisclaimer/' + guid)
      .then(response => {
        resolve(response.data as DirectDisclaimerResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('IS_DIRECT_DISCLAIMER_FAILED'));
      });
  });
}

export async function isDirectDisclaimerServer(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/shorturl/v1/directdisclaimer')
      .then(response => {
        resolve(response.data as boolean);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('IS_DIRECT_DISCLAIMER_FAILED'));
      });
  });
}

export async function doJobDashboardSearchServer(
  requestObj: JobDashboardSearchRequest
): Promise<JobDashboardSearchResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobdashboard/v1/search', requestObj)
      .then(response => {
        resolve(response.data as JobDashboardSearchResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_DASHBOARD_SEARCH_FAILED'));
      });
  });
}

export async function getChatMessagesServer(
  requestObj: ChatMessageRequest
): Promise<ChatMessageResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/chat/v1/messages', {
        params: {
          interviewGuid: requestObj.interviewGuid,
          slug: requestObj.slug
        }
      })
      .then(response => {
        resolve(response.data as ChatMessageResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CHAT_MESSAGES_FAILED'));
      });
  });
}

export async function getAllMessageCenterContactsServer(
  request: AllChatMessageRequestDto
): Promise<AllChatMessageResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/allmessages', request)
      .then(response => {
        resolve(response.data as AllChatMessageResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ALL_CHAT_MESSAGES_FAILED'));
      });
  });
}

export async function getAllMessagesServer(
  request: AllChatMessageRequestDto
): Promise<AllChatMessageResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/chat/v1/allmessages', request)
      .then(response => {
        resolve(response.data as AllChatMessageResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ALL_CHAT_MESSAGES_FAILED'));
      });
  });
}

export async function getAllChatMessagesCountServer(): Promise<
  AllChatMessagescountResponse
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/chat/v1/allMessagesCount')
      .then(response => {
        resolve(response.data as AllChatMessagescountResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ALL_CHAT_MESSAGES_FAILED'));
      });
  });
}

export async function confirmMessageReadServer(
  requestObj: ChatMessageRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/chat/v1/confirmread', {
        params: {
          slug: requestObj.slug,
          interviewGuid: requestObj.interviewGuid
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CONFIRM_READ_CHAT_MESSAGE_FAILED'));
      });
  });
}

export async function sendChatMessageServer(
  requestObj: SendChatMessageRequest
): Promise<string> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/chat/v1/send', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 417) {
            reject(new Error('CHAT_TIMEOUT'));
          }
        }
        reject(new Error('SEND_CHAT_MESSAGE_FAILED'));
      });
  });
}

export async function sendTemplateChatMessageServer(
  requestObj: SendChatMessageRequest
): Promise<ChatMessage> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/chat/v1/sendtrigger', requestObj)
      .then(response => {
        resolve(response.data as ChatMessage);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SEND_TEMPLATE_CHAT_MESSAGE_FAILED'));
      });
  });
}

export async function doJobDashboardStatusChangeServer(
  requestObj: JobDashboardStatusChangeRequest
): Promise<JobDashboardStatusChangeResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobdashboard/v1/statusupdate', requestObj)
      .then(response => {
        resolve(response.data as JobDashboardStatusChangeResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_DASHBOARD_STATUS_CHANGE_FAILED'));
      });
  });
}

export async function doStatusTransitionServer(
  requestObj: TransitionStatusRequest
): Promise<RecruiterInterview> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/recruiter-interview/v1/status-transition', requestObj)
      .then(response => {
        resolve(response.data as RecruiterInterview);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('INTERVIEW_STATUS_TRANSITION_FAILED'));
      });
  });
}

export async function doLogActionServer(
  requestObj: ActionLogRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/recruiter-interview/v1/log-action', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('INTERVIEW_ACTION_LOG_FAILED'));
      });
  });
}

export async function sendWebMessengerTextServer(
  message: Message
): Promise<Message> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/webmessenger/sendmessage', message)
      .then(response => {
        resolve(response.data as Message);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('SEND_MESSAGE_ERROR'));
      });
  });
}

export async function initWebMessengerServer(
  initMessage: InitMessage
): Promise<InitMessage> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/webmessenger/init', initMessage)
      .then(response => {
        resolve(response.data as InitMessage);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('INIT_MESSENGER_ERROR'));
      });
  });
}

export async function webMessengerUploadMediaServer(
  formData: Object
): Promise<Message[]> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/webmessenger/uploadmedia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as Message[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('UPLOAD_MEDIA_ERROR'));
      });
  });
}

export async function tenantLogoUploadMediaServer(
  formData: Object
): Promise<string> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/masterdata/v1/uploadlogo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as string);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('UPLOAD_TENANT_LOGO_MEDIA_ERROR'));
      });
  });
}

export async function chatMessageUploadMediaServer(
  formData: Object
): Promise<ChatMessage> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/chat/v1/uploadmedia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as ChatMessage);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('UPLOAD_CHAT_MEDIA_ERROR'));
      });
  });
}

export async function stepMediaUploadServer(
  formData: Object
): Promise<StepMedia> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/jobadministration/v1/job/uploadmedia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as StepMedia);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('UPLOAD_STEP_MEDIA_ERROR'));
      });
  });
}

export async function doChangePasswordServer(
  changePasswordRequest: ChangePasswordRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/dashboard/v1/changePassword', changePasswordRequest)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 403) {
            reject(new Error('TFA_ERROR'));
          }
        } else {
          reject(new Error('CHANGE_PASSWORD_FAILED'));
        }
      });
  });
}

export async function doPasswordResetServer(
  resetPasswordRequest: ResetPasswordRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/dashboard/v1/resetPassword', resetPasswordRequest)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('RESET_PASSWORD_FAILED'));
        }
      });
  });
}

export async function doValidateNewPasswordTokenServer(
  validateNewPasswordTokenRequest: NewPasswordRequest
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/newpassword/v1/validatetoken', validateNewPasswordTokenRequest)
      .then(response => {
        resolve(response.data as boolean);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('VALIDATE_NEW_PASSWORD_TOKEN_FAILED'));
        }
      });
  });
}
export async function doChangePasswordByTokenServer(
  newPasswordTokenRequest: NewPasswordRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/newpassword/v1/save', newPasswordTokenRequest)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('SAVE_NEW_PASSWORD_FAILED'));
        }
      });
  });
}

export async function fetchSitesServer(
  requestObj: SiteSelectionRequest
): Promise<SiteSelectionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/picked-sites', requestObj)
      .then(response => {
        resolve(response.data as SiteSelectionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('LOAD_SITES_FAILED'));
      });
  });
}

export async function fetchChangeableSitesServer(
  requestObj: SiteSelectionRequest
): Promise<SiteSelectionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/picked-cbsites', requestObj)
      .then(response => {
        resolve(response.data as SiteSelectionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('LOAD_SITES_FAILED'));
      });
  });
}

export async function saveSiteInfoServer(requestObj: Site): Promise<Site> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/site', requestObj)
      .then(response => {
        resolve(response.data as Site);
      })
      .catch(error => {
        if (error && error.response) {
          if (
            error.response.data &&
            'SITE_ADDRESS_NOT_VALID' === error.response.data.message
          ) {
            reject(new Error('SITE_ADDRESS_NOT_VALID'));
          } else {
            console.log(error.response);
            if (error.response.status && error.response.status === 400) {
              reject(new Error('TOKEN_ERROR'));
            }
          }
        }
        reject(new Error('SAVE_SITE_FAILED'));
      });
  });
}

export async function saveOriginInfoServer(
  requestObj: Origin
): Promise<Origin> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/origin', requestObj)
      .then(response => {
        resolve(response.data as Origin);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_ORIGIN_FAILED'));
      });
  });
}

export async function saveJobInfoServer(
  requestObj: JobInfoRequest
): Promise<JobInfoResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/job', requestObj)
      .then(response => {
        resolve(response.data as JobInfoResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_INFO_FAILED'));
      });
  });
}

export async function saveSitesAndOriginsServer(
  requestObj: JobInfoRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/savesites', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_SITES_ORIGINS_FAILED'));
      });
  });
}

export async function fetchJobInfoServer(
  requestObj: IJobInfoRequest
): Promise<JobInfoResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/' + requestObj.slug, {
        params: {
          language: requestObj.languageCode
        }
      })
      .then(response => {
        resolve(response.data as JobInfoResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FETCH_JOB_INFO_FAILED'));
      });
  });
}

export async function fetchJobCategoriesServer(): Promise<JobCategoryResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/jobcategories')
      .then(response => {
        resolve(response.data as JobCategoryResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FETCH_JOB_CATEGORIES_FAILED'));
      });
  });
}

export async function createSalesTestUserServer(
  requestObj: SalesUserRequest
): Promise<SalesUserResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/sales/v1/user', requestObj)
      .then(response => {
        resolve(response.data as SalesUserResponse);
      })
      .catch(error => {
        if (error && error.response) {
          if (
            error.response.data &&
            'USER_ALREADY_EXISTS' === error.response.data.message
          ) {
            reject(new Error('USER_ALREADY_EXISTS'));
          } else if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
            console.log(error.response);
          } else {
            console.log(error.response);
          }
        }
        reject(new Error('GET_SALES_USER_FAILED'));
      });
  });
}

export async function initSalesSessionServer(): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/sales/v1/init')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
          console.log(error.response);
        }
        reject(new Error('INIT_SALES_FAILED'));
      });
  });
}

export async function fetchCountriesOptionServer(): Promise<
  CountriesOptionResponse
> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/privacy/v1/countries')
      .then(response => {
        resolve(response.data as CountriesOptionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('FETCH_COUNTRIES_FAILED'));
      });
  });
}

export async function fetchMoreCountriesOptionServer(): Promise<
  CountriesOptionResponse
> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/privacy/v1/morecountries')
      .then(response => {
        resolve(response.data as CountriesOptionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('FETCH_MORE_COUNTRIES_FAILED'));
      });
  });
}

export async function fetchLanguageOptionServer(): Promise<
  CountriesOptionResponse
> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/privacy/v1/languages')
      .then(response => {
        resolve(response.data as CountriesOptionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('FETCH_LANGUAGES_FAILED'));
      });
  });
}

export async function fetchLanguageOptionTemplateServer(
  requestObj: LoadLanguageTemplatesRequest
): Promise<CountriesOptionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/languages-template', {
        params: {
          interviewGuid: requestObj.interviewGuid,
          templateMessageType: requestObj.templateMessageType
        }
      })
      .then(response => {
        resolve(response.data as CountriesOptionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('FETCH_LANGUAGES_FAILED'));
      });
  });
}

export async function detectLanguageServer(
  requestObj: TranslationPropTextDto
): Promise<string> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/messagecenter/v1/detectlanguage', requestObj)
      .then(response => {
        resolve(response.data as string);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DETECT_LANGUAGE_FAILED'));
      });
  });
}

export async function validateSalesTokenServer(
  salesToken: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/privacy/v1/salestoken', { params: { salesToken: salesToken } })
      .then(response => {
        resolve(response.data as boolean);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('VALIDATE_SALES_TOKEN_FAILED'));
      });
  });
}

export async function initWhatsAppPageServer(
  languageCode: string
): Promise<PrivacyConfTextResponseDto> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/privacy/v1/init', { params: { languageCode: languageCode } })
      .then(response => {
        resolve(response.data as PrivacyConfTextResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('INIT_WHATS_APP_PAGE_FAILED'));
      });
  });
}

export async function doJobStatusChangeServer(
  requestObj: JobStatusChangeRequest
): Promise<JobStatusChangeResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobs/v1/updateStatus', requestObj)
      .then(response => {
        resolve(response.data as JobStatusChangeResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_STATUS_CHANGE_FAILED'));
      });
  });
}

export async function doRejectInterviewsServer(
  requestObj: RejectInterviewRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobdashboard/v1/reject', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('REJECT_INTERVIEW_FAILED'));
      });
  });
}

export async function saveJobInterviewServer(
  requestObj: JAWInterviewRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/job/interview', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_INTERVIEW_FAILED'));
      });
  });
}

export async function findJobInterviewServer(
  requestObj: IJAWInterviewRequest
): Promise<JAWInterviewRequest> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/job/interview', {
        params: {
          jobSlug: requestObj.slug,
          language: requestObj.languageCode
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_INTERVIEW_FAILED'));
      });
  });
}

export async function findPropKeysServer(
  requestObj: JAWConfigRequest
): Promise<JAWConfig> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/job/propkeys', {
        params: {
          jobSlug: requestObj.slug,
          language: requestObj.languageCode,
          salutation: requestObj.salutation
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_PROP_KEYS_JOB_CONFIG_FAILED'));
      });
  });
}

export async function findPropKeysByTenantServer(
  requestObj: JAWConfigByTenantRequest
): Promise<JAWConfig> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/propkeys', {
        params: {
          language: requestObj.languageCode,
          salutation: requestObj.salutation
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_PROP_KEYS_CONFIG_FAILED'));
      });
  });
}

export async function findJobOriginsServer(jobSlug: string): Promise<Origin[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/job/origins', {
        params: {
          jobSlug: jobSlug
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_ORIGINS_FAILED'));
      });
  });
}

export async function findJobOriginSelectionsServer(
  jobSlug: string
): Promise<OriginSelectionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/picked-origins', {
        params: {
          jobSlug: jobSlug
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_ORIGIN_SELECTIONS_FAILED'));
      });
  });
}

export async function findAppPropsServer(jobSlug: string): Promise<JAWConfig> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/job/appprops', {
        params: {
          jobSlug: jobSlug
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_APP_PROPS_JOB_CONFIG_FAILED'));
      });
  });
}

export async function saveJobConfigServer(requestObj: JAWConfig): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/job/config', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_CONFIG_FAILED'));
      });
  });
}

export async function saveTenantConfigServer(
  requestObj: JAWConfig
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/config', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_CONFIG_FAILED'));
      });
  });
}

export async function doGetReportTopApplicantsServer(): Promise<
  ReportTopApplicantResponse
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/report/v1/topapplicants')
      .then(response => {
        resolve(response.data as ReportTopApplicantResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REPORT_TOP_APPLICANTS_FAILED'));
      });
  });
}

export async function doGetReportNoOfApplicantsServer(): Promise<ChartDataDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/report/v1/numberapplicants')
      .then(response => {
        resolve(response.data as ChartDataDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REPORT_NO_OF_APPLICANTS_FAILED'));
      });
  });
}
export async function fetchJobUrlsServer(
  requestObj: JobUrlsRequest
): Promise<JobUrlsResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/joburls/v1/' + requestObj.slug, {
        params: {
          languageCode: requestObj.languageCode
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_JOB_URLS_FAILED'));
        }
      });
  });
}

export async function doGetReportInitialApplicantsPerJobServer(): Promise<
  ChartDataDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/report/v1/initalapplicantsperjob')
      .then(response => {
        resolve(response.data as ChartDataDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REPORT_INITIAL_APPLICANTS_PER_JOB_FAILED'));
      });
  });
}

export async function doGetReportApplicantsFunnelServer(): Promise<
  FunnelDataDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/report/v1/applicantsfunnel')
      .then(response => {
        resolve(response.data as FunnelDataDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REPORT_APPLICANTS_FUNNEL_FAILED'));
      });
  });
}

export async function doShareInterviewServer(
  requestObj: ShareInterviewRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/recruiter-interview/v1/share', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SHARE_INTERVIEW_FAILED'));
      });
  });
}

export async function doShareExistsServer(shareGuid: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/interview/v1/share/' + shareGuid)
      .then(response => {
        resolve(response.data as boolean);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('SHARE_EXISTS_FAILED'));
      });
  });
}

export async function getSharedInterviewServer(
  requestObj: GetShareInterviewRequest
): Promise<GetShareInterviewResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/interview/v1/getshare', requestObj)
      .then(response => {
        resolve(response.data as GetShareInterviewResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_SHARE_INTERVIEW_FAILED'));
      });
  });
}

export async function getSharedInterviewContentServer(
  requestObj: GetShareInterviewContent
): Promise<ShareInterview> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/interview/v1/getsharedinterview', requestObj)
      .then(response => {
        resolve(response.data as ShareInterview);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_SHARE_CONTENT_INTERVIEW_FAILED'));
      });
  });
}

export async function getSharedInterviewCommentsServer(
  requestObj: GetShareInterviewRequest
): Promise<CommentResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/interview/v1/share/comments', requestObj)
      .then(response => {
        resolve(response.data as CommentResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_SHARE_INTERVIEW_COMMENTS_FAILED'));
      });
  });
}

export async function doAddExternalCommentServer(
  requestObj: ExternalCommentRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/interview/v1/share/addcomment', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('ADD_SHARE_INTERVIEW_COMMENTS_FAILED'));
      });
  });
}

export async function getTenantInfoServer(): Promise<TenantInfo> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/tenantInfo')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_TENANT_INFO_FAILED'));
      });
  });
}

export async function saveTenantInfoServer(
  tenantInfo: TenantInfo
): Promise<TenantInfo> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/tenantInfo', tenantInfo)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('SAVE_TENANT_INFO_FAILED'));
      });
  });
}

export async function deleteSiteServer(site: Site): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/site/delete', site)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('DELETE_SITE_FAILED'));
      });
  });
}

export async function deleteOriginServer(origin: Origin): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/origin/delete', origin)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('DELETE_ORIGIN_FAILED'));
      });
  });
}
export async function saveOriginServer(origin: Origin): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/origin', origin)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('SAVE_ORIGIN_FAILED'));
      });
  });
}

export async function saveSiteServer( requestObj: CreateSiteRequest): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/site', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error);
          if (error.response.status && error.response.status === 409) {
            reject(new Error('SITE_ADDRESS_NOT_VALID'));
          } else {
            console.log(error.response);
            if (error.response.status && error.response.status === 400) {
              reject(new Error('TOKEN_ERROR'));
            }
          }
        }
        reject(new Error('SAVE_SITE_FAILED'));
      });
  });
}

export async function getSitesServer(): Promise<Site[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/sites')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SITES_FAILED'));
      });
  });
}

export async function getOriginsServer(): Promise<Origin[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/origins')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ORIGINS_FAILED'));
      });
  });
}

export async function getSiteSelectionServer(
  requestObj: SiteSelectionRequest
): Promise<SiteSelectionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/picked-sites', requestObj)
      .then(response => {
        resolve(response.data as SiteSelectionResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SITE_SELECTION_FAILED'));
      });
  });
}

export async function getOriginSelectionsServer(
  jobSlug: string
): Promise<OriginSelectionResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/picked-origins', {
        params: {
          jobSlug: jobSlug
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_ORIGIN_SELECTIONS_FAILED'));
      });
  });
}

export async function fetchAllJobsServer(
  jobListRequest: JobListRequest
): Promise<JobListResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobs/v1/all', jobListRequest)
      .then(response => {
        resolve(response.data as JobListResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('ALL_JOBS_CALL_FAILED'));
      });
  });
}

export async function getSharedEmailsServer(): Promise<[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/recruiter-interview/v1/shared-emails')
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_USED_EMAILS_FAILED'));
      });
  });
}

export async function getRootGroupsServer(): Promise<GroupsResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1')
      .then(response => {
        resolve(response.data as GroupsResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_ROOT_GROUPS_FAILED'));
        }
      });
  });
}

export async function createNewGroupServer(
  requestObj: GroupDto
): Promise<GroupDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantadmin/v1/group', requestObj)
      .then(response => {
        resolve(response.data as GroupDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 409) {
            reject(new Error('GROUP_ALREADY_EXISTS'));
          }
        }
        reject(new Error('CREATE_NEW_GROUP_FAILED'));
      });
  });
}

export async function updateGroupServer(
  requestObj: GroupDto
): Promise<GroupDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantadmin/v1/update', requestObj)
      .then(response => {
        resolve(response.data as GroupDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_GROUP_FAILED'));
      });
  });
}

export async function deleteGroupServer(
  requestObj: GroupDto
): Promise<GroupDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantadmin/v1/delete', requestObj)
      .then(response => {
        resolve(response.data as GroupDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 409) {
            reject(new Error('GROUP_NOT_EMPTY'));
          }
        }
        reject(new Error('DELETE_GROUP_FAILED'));
      });
  });
}

export async function getChildGroupsServer(
  parentSlug: string
): Promise<GroupDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/childs', {
        params: { parentSlug: parentSlug }
      })
      .then(response => {
        resolve(response.data as GroupDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CHILD_GROUPS_FAILED'));
      });
  });
}
export async function getGroupsUserServer(
  groupSlug: string
): Promise<SelectableUser[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/groupuser', {
        params: { groupSlug: groupSlug }
      })
      .then(response => {
        resolve(response.data as SelectableUser[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_GROUPS_USER_FAILED'));
      });
  });
}

export async function getSelectableUserServer(
  groupSlug: string
): Promise<SelectableUser[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/user', {
        params: { groupSlug: groupSlug }
      })
      .then(response => {
        resolve(response.data as SelectableUser[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SELECTABLE_USER_FAILED'));
      });
  });
}

export async function assignUserServer(
  requestObj: AssignUserRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantadmin/v1/assignuser', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ASSIGN_USER_FAILED'));
      });
  });
}

export async function getTenantAdminUsersServer(): Promise<User[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/useradmin/v1')
      .then(response => {
        resolve(response.data as User[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_TENANT_ADMIN_USERS_FAILED'));
      });
  });
}

export async function updateUserServer(requestObj: User): Promise<User> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/useradmin/v1/update', requestObj)
      .then(response => {
        resolve(response.data as User);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 409) {
            reject(new Error('USER_ALREADY_EXISTS'));
          }
        }
        reject(new Error('UPDATE_USER_FAILED'));
      });
  });
}

export async function deleteUserServer(requestObj: User): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/useradmin/v1/delete', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DELETE_USER_FAILED'));
      });
  });
}

export async function updateRoleServer(requestObj: Role): Promise<Role> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantadmin/v1/updaterole', requestObj)
      .then(response => {
        resolve(response.data as Role);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_ROLE_FAILED'));
      });
  });
}

export async function getRoleServer(requestObj: string): Promise<Role> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/useradmin/v1/role', { params: { groupSlug: requestObj } })
      .then(response => {
        resolve(response.data as Role);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ROLE_FAILED'));
      });
  });
}

export async function getSelectableSitesServer(
  requestObj: string
): Promise<SelectableSite[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/rolesites', {
        params: { roleSlug: requestObj }
      })
      .then(response => {
        resolve(response.data as SelectableSite[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SELECTABLE_SITES_FAILED'));
      });
  });
}

export async function getSelectableJobsServer(
  requestObj: string
): Promise<SelectableJob[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/rolejobs', {
        params: { roleSlug: requestObj }
      })
      .then(response => {
        resolve(response.data as SelectableJob[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SELECTABLE_JOBS_FAILED'));
      });
  });
}

export async function isCredServer(requestObj: any): Promise<boolean | null> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/useradmin/v1/cred', {
        params: { jobSlug: requestObj.jobSlug, lvl: requestObj.lvl }
      })
      .then(response => {
        resolve(response.data as boolean | null);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('IS_CRED_FAILED'));
      });
  });
}

export async function copyJobServer(
  copyJobRequest: CopyJobRequest
): Promise<CopyJobResult[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobs/v1/copy', copyJobRequest)
      .then(response => {
        resolve(response.data as CopyJobResult[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('COPY_JOB_CALL_FAILED'));
      });
  });
}

export async function getAssignableGroupJobsServer(
  requestObj: string
): Promise<SelectableJob[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/groupjobs', {
        params: { groupSlug: requestObj }
      })
      .then(response => {
        resolve(response.data as SelectableJob[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSIGNABLE_GROUP_JOBS_FAILED'));
      });
  });
}

export async function getAllJobClustersServer(
  languageCode: string
): Promise<JobClusterItem[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/job-clusters/v1/all', {
        params: {
          language: languageCode
        }
      })
      .then(response => {
        resolve(response.data as JobClusterItem[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ALL_JOB_CLUSTERS_FAILED'));
      });
  });
}

export async function saveJobClusterServer(
  requestObj: SaveJobClusterRequest
): Promise<JobClusterItem> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/job-clusters/v1/save', requestObj)
      .then(response => {
        resolve(response.data as JobClusterItem);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ALL_JOB_CLUSTERS_FAILED'));
      });
  });
}

export async function getApplicantSearchResultServer(
  requestObj: ApplicantSearchRequest
): Promise<ApplicantSearchResult> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/applicantssearch/v1/search', requestObj)
      .then(response => {
        resolve(response.data as ApplicantSearchResult);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_APPLICANT_SEARCH_FAILED'));
      });
  });
}

export async function getApplicantSearchSelectableValuesServer(
  requestObj: ExternalJobSetting
): Promise<ApplicantSearchSelectableValues> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/applicantssearch/v1/filtervalues', {
        params: {
          postingJobs: requestObj.postingJob,
          shortUrlWithExternalTitle: requestObj.shortUrlWithExternalTitle
        }
      })
      .then(response => {
        resolve(response.data as ApplicantSearchSelectableValues);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SELECTABLE_VALUES_FAILED'));
      });
  });
}

export async function getApplicantSearchExcelExportServer(
  requestObj: ApplicantSearchRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/applicantssearch/v1/excelexport', requestObj, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_EXCEL_EXPORT_FAILED'));
      });
  });
}

export async function bookmarkSiteServer(requestObj: string): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/recruiter-interview/v1/bookmark-site', {
        params: {
          slug: requestObj
        }
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('BOOKMARK_SITE_FAILED'));
      });
  });
}

export async function activateJobSiteServer(
  requestObj: ActivateJobSiteRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/activatejobsite', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ACTIVATE_JOB_SITE_FAILED'));
      });
  });
}

export async function deactivateJobSiteServer(
  requestObj: ActivateJobSiteRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/deactivatejobsite', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DEACTIVATE_JOB_SITE_FAILED'));
      });
  });
}

export async function updatePublishedPlatformServer(
  requestObj: PublishedPlatformRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/update-publish-platform', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_PUBLISED_PLATFORM_FAILED'));
      });
  });
}

export async function updateUserStatusServer(
  requestObj: UpdateUserStatusRequest
): Promise<User> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/useradmin/v1/update-status', requestObj)
      .then(response => {
        resolve(response.data as User);
        reject(new Error('UPDATE_USER_STATUS_FAILED'));
      });
  });
}

export async function directAssignUserServer(
  requestObj: DirectAssignUserGroup
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantadmin/v1/createandassignuser', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DIRECT_ASSIGN_USER_FAILED'));
      });
  });
}

export async function getRecentApplicationsServer(
  requestObj: string
): Promise<RecentApplication[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/recruiter-interview/v1/recent-applications', {
        params: {
          slug: requestObj
        }
      })
      .then(response => {
        resolve(response.data as RecentApplication[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_RECENT_APPLICATIONS_FAILED'));
      });
  });
}

export async function doGetReportApplicantsPerJobServer(): Promise<
  ChartDataDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/report/v1/applicantsperjob')
      .then(response => {
        resolve(response.data as ChartDataDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REPORT_APPLICANTS_PER_JOB_FAILED'));
      });
  });
}

export async function updateCandidateNameServer(
  requestObj: UpdateCandidateName
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/recruiter-interview/v1/update-candidatename', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CANDIDATE_NAME_FAILED'));
      });
  });
}

export async function getApplicationPropertiesServer(): Promise<
  JAWApplicationConfig[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantconfig/v1/app-props')
      .then(response => {
        resolve(response.data as JAWApplicationConfig[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_APPLICATION_PROPERTIES_FAILED'));
      });
  });
}

export async function updateApplicationPropertiesServer(
  requestObj: JAWApplicationConfig[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantconfig/v1/update-app-props', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_APPLICATION_PROPERTIES_FAILED'));
      });
  });
}

export async function isCreateTenantIdentifierValidServer(
  identifier: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantconfig/v1/valid-identifier', {
        params: {
          newTenantIdentifier: identifier
        }
      })
      .then(response => {
        resolve(response.data as boolean);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('IS_CREATE_TENANT_IDENTIFIER_VALID_FAILED'));
      });
  });
}

export async function createNewTenantServer(formData: Object): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/tenantconfig/v1/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CREATE_NEW_TENANT_FAILED'));
      });
  });
}

export async function getJobTemplatesServer(): Promise<JobTemplateResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobtemplate/v1/search')
      .then(response => {
        resolve(response.data as JobTemplateResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_TEMPLATES_FAILED'));
      });
  });
}

export async function getJobTemplateServer(
  slug: string
): Promise<JobTemplateListDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobtemplate/v1/' + slug)
      .then(response => {
        resolve(response.data as JobTemplateListDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_TEMPLATE_FAILED'));
      });
  });
}

export async function saveJobTemplateServer(
  requestObj: JobTemplateListDto
): Promise<JobTemplateListDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobtemplate/v1/save', requestObj)
      .then(response => {
        resolve(response.data as JobTemplateListDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_TEMPLATE_FAILED'));
      });
  });
}

export async function getJobTemplateQuestionsServer(
  slug: string
): Promise<JAWInterviewRequest> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobtemplate/v1/questions/' + slug)
      .then(response => {
        resolve(response.data as JAWInterviewRequest);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_TEMPLATE_QUESTIONS_FAILED'));
      });
  });
}

export async function saveJobTemplateInterviewServer(
  requestObj: JobTemplateInterviewRequest
): Promise<JAWInterviewRequest> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobtemplate/v1/saveinterview', requestObj)
      .then(response => {
        resolve(response.data as JAWInterviewRequest);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_TEMPLATE_INTERVIEW_FAILED'));
      });
  });
}

export async function getJobTemplateTagsServer(
  slug: string
): Promise<JobTemplateTag[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobtemplate/v1/tags', {
        params: {
          jobTemplateSlug: slug
        }
      })
      .then(response => {
        resolve(response.data as JobTemplateTag[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_TEMPLATE_TAGS_FAILED'));
      });
  });
}

export async function saveJobTemplateTagServer(
  requestObj: JobTemplateTagRequest
): Promise<JobTemplateTag> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobtemplate/v1/savejobtemplatetag', requestObj)
      .then(response => {
        resolve(response.data as JobTemplateTag);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_TEMPLATE_TAG_FAILED'));
      });
  });
}

export async function getMessageTemplatesServer(): Promise<MessageTemplate[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantconfig/v1/messagetemplates')
      .then(response => {
        resolve(response.data as MessageTemplate[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_MESSAGE_TEMPLATES_FAILED'));
      });
  });
}

export async function getDisclaimerTargetServer(
  request: ShortUrlRequest
): Promise<DisclaimerTargetResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/shorturl/v1/directdisclaimer', request)
      .then(response => {
        resolve(response.data as DisclaimerTargetResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_DISCLAIMER_TARGET_FAILED'));
      });
  });
}

export async function applyDisclaimerServer(
  request: ShortUrlRequest
): Promise<DisclaimerTargetResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/shorturl/v1/applydisclaimer', request)
      .then(response => {
        resolve(response.data as DisclaimerTargetResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('APPLY_DISCLAIMER_FAILED'));
      });
  });
}

export async function getSelectableJobTemplatesServer(): Promise<
  SelectableJobTemplate[]
> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/tenantconfig/v1/jobtemplates')
      .then(response => {
        resolve(response.data as SelectableJobTemplate[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_SELECTABLE_JOBTEMPLATES_FAILED'));
      });
  });
}

export async function updateUserNotificationsServer(
  requestObj: User
): Promise<User> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/useradmin/v1/update-notifications', requestObj)
      .then(response => {
        resolve(response.data as User);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 409) {
            reject(new Error('USER_ALREADY_EXISTS'));
          }
        }
        reject(new Error('UPDATE_USER_NOTIFICATIONS_FAILED'));
      });
  });
}

export async function createNewJobSiteServer(
  requestObj: CreateJobSiteRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobadministration/v1/createsite', requestObj)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error);
          if (error.response.status && error.response.status === 409) {
            reject(new Error('SITE_ADDRESS_NOT_VALID'));
          } else {
            console.log(error.response);
            if (error.response.status && error.response.status === 400) {
              reject(new Error('TOKEN_ERROR'));
            }
          }
        }
        reject(new Error('CREATE_NEW_JOB_SITE_FAILED'));
      });
  });
}

export async function setChatTranslationServer(
  requestObj: SetChatTranslationRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/chat/v1/setchattranslation', {
        params: {
          interviewGuid: requestObj.interviewGuid,
          enable: requestObj.enable
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SET_CHAT_TRANSLATION_FAILED'));
      });
  });
}

export async function fetchFunnelReportsServer(
  funnelreportRequest: FunnelReportRequest
): Promise<FunnelReportResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/reports/v1/funnel', funnelreportRequest)
      .then(response => {
        resolve(response.data as FunnelReportResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FUNNEL_REPORT_ERROR'));
      });
  });
}

export async function fetchStackChartReportsServer(
  funnelreportRequest: FunnelReportRequest
): Promise<StackChartReportResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/reports/v1/stack', funnelreportRequest)
      .then(response => {
        resolve(response.data as StackChartReportResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('FUNNEL_REPORT_ERROR'));
      });
  });
}

export async function fetchJobsFilterResultsServer(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get(
        'api/reports/v1/filter/jobs?tenantIds=' +
          searchRequest.tenants +
          '&groupIds=' +
          searchRequest.groups
      )
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOBS_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchTenantFilterResultsServer(): Promise<
  ReportsFilterResponse[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/tenants')
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('TENANTS_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchOriginFilterResultsServer(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    const originUrl = 'api/reports/v1/filter/origins?tenantIds=';
    pitchYouDataAPi
      .get('api/reports/v1/filter/origins?tenantIds=' + searchRequest.tenants)
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ORIGINS_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchRemoteSystemFilterResultsServer(): Promise<
  ReportsFilterResponse[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/remoteSystems')
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('REMOTE_SYSTEM_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchGroupsServer(
  tenantId: number | null
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    let groupUrl = 'api/reports/v1/filter/groups?tenantId=';
    if (tenantId != null) {
      groupUrl = 'api/reports/v1/filter/groups?tenantId=' + tenantId;
    }
    pitchYouDataAPi
      .get(groupUrl)
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GROUPS_RESPONSE_ERROR'));
      });
  });
}

export async function getMessageCenterContactServer(
  interviewGuid: string
): Promise<MessageCenterContact> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/contact', {
        params: {
          interviewGuid: interviewGuid
        }
      })
      .then(response => {
        resolve(response.data as MessageCenterContact);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_MESSAGE_CENTER_CONTACT_FAILED'));
      });
  });
}

export async function updateUserCredentialServer(
  request: UpdateCredentialRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/useradmin/v1/updatecredential', {
        params: {
          type: request.type,
          active: request.active,
          slug: request.slug
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_USER_CREDENTIAL_FAILED'));
      });
  });
}

export async function createNewContactServer(
  request: Contact
): Promise<string> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/createcontact', request)
      .then(response => {
        resolve(response.data as string);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 409) {
            reject(new Error('INVALID_CONTACT'));
          }
        }
        reject(new Error('CREATE_NEW_CONTACT_FAILED'));
      });
  });
}

export async function uploadContactsExcelServer(
  formData: FormData
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/importContacts', formData, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          } else if (error.response.status && error.response.status === 409) {
            reject(new Error('INVALID_CONTACT'));
          }
        }
        reject(new Error('CREATE_NEW_CONTACT_FAILED'));
      });
  });
}

export async function removeContactServer(request: string): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/removecontact', {
        params: {
          guid: request
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('REMOVE_CONTACT_FAILED'));
      });
  });
}

export async function updateContactServer(request: Contact): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/updatecontact', request)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CONTACT_FAILED'));
      });
  });
}

export async function fetchReportsExcelExportServer(
  requestObj: FunnelReportRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/reports/v1/export', requestObj, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_EXCEL_EXPORT_FAILED'));
      });
  });
}

export async function fetchJobMainsCredentialsServer(): Promise<
  JobsMainCredentials
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobdashboard/v1/jobmainscredentials')
      .then(response => {
        resolve(response.data as JobsMainCredentials);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_MAINS_CREDENTIALS_FAILED'));
      });
  });
}

export async function archiveJobServer(jobSlug: string): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/jobadministration/v1/job/' + jobSlug + '/archive')
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ARCHIVE_JOB_FAILED'));
      });
  });
}

export async function getQuestionnairesServer(
  request: QuestionnaireSearchRequestDto
): Promise<QuestionnaireSearchResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/questionnaires', request)
      .then(response => {
        resolve(response.data as QuestionnaireSearchResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_QUESTIONNAIRES_FAILED '));
      });
  });
}

export async function updateQuestionnaireServer(
  request: QuestionnaireDetailDto
): Promise<QuestionnaireDetailDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/update-questionnaire', request)
      .then(response => {
        resolve(response.data as QuestionnaireDetailDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_QUESTIONNAIRE_FAILED '));
      });
  });
}

export async function getQuestionnaireServer(
  request: string
): Promise<QuestionnaireDetailDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/questionnaire', {
        params: {
          slug: request
        }
      })
      .then(response => {
        resolve(response.data as QuestionnaireDetailDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_QUESTIONNAIRE_FAILED '));
      });
  });
}

export async function getAssessmentRunsServer(
  request: AssessmentSearchRequestDto
): Promise<AssessmentSearchResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/assessmentruns', request)
      .then(response => {
        resolve(response.data as AssessmentSearchResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSESSMENT_RUNS_FAILED '));
      });
  });
}

export async function getActiveQuestionnairesServer(
  type: QuestionnaireType
): Promise<QuestionnaireSearchItemDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/activequestionnaires', {
        params: {
          questionnaireType: type
        }
      })
      .then(response => {
        resolve(response.data as QuestionnaireSearchItemDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ACTIVE_QUESTIONNAIRES_FAILED '));
      });
  });
}

export async function getMessageCenterContactsServer(
  requestDto: MessageCenterContactSearchRequestDto
): Promise<MessageCenterContactSearchResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/searchcontacts', requestDto)
      .then(response => {
        resolve(response.data as MessageCenterContactSearchResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SEARCH_MESSAGE_CENTER_CONTACTS_FAILED '));
      });
  });
}

export async function updateAssessmentRunServer(
  requestDto: AssessmentRunDto
): Promise<AssessmentRunDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/updateassessment', requestDto)
      .then(response => {
        resolve(response.data as AssessmentRunDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_ASSESSMENT_FAILED '));
      });
  });
}

export async function updateMessagesRunServer(
  slugList: string[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/chat/v1/messages/updateReadAt', slugList)
      .then(response => {
        resolve(response.data as ChatMessage[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CHAT_MESSAGES_FAILED '));
      });
  });
}

export async function getAssessmentRunDetailServer(
  request: AssessmentDetailRequestDto
): Promise<AssessmentRunDetailDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/assessmentrundetails', request)
      .then(response => {
        resolve(response.data as AssessmentRunDetailDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSESSMENT_RUN_DETAILS_FAILED '));
      });
  });
}

export async function getRecruitMeMessagesServer(
  request: string
): Promise<RecruitMeMessagesResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/messages', {
        params: {
          guid: request
        }
      })
      .then(response => {
        resolve(response.data as RecruitMeMessagesResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_RECRUITME_MESSAGES_FAILED '));
      });
  });
}

export async function cancelAssessmentServer(
  request: string
): Promise<MessageCenterContact> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/cancel-assessment', {
        params: {
          guid: request
        }
      })
      .then(response => {
        resolve(response.data as MessageCenterContact);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CANCEL_ASSESSMENT_FAILED '));
      });
  });
}

export async function cancelAssessmentRunServer(request: string): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/cancel-assessment-run', {
        params: {
          slug: request
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CANCEL_ASSESSMENT_RUN_FAILED '));
      });
  });
}

export async function getAssessmentRunServer(
  assessmentRunSlug: string
): Promise<AssessmentRunDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/assessmentruns/' + assessmentRunSlug)
      .then(response => {
        resolve(response.data as AssessmentRunDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSESSMENT_RUN_FAILED '));
      });
  });
}

export async function sendBulkMessagesServer(
  requestDto: BulkMessageRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/bulk-messages/v1/send-bulk', requestDto)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SEND_BULK_MESSAGES_FAILED '));
      });
  });
}

export async function getAdditionalProfileInfosServer(
  guid: string
): Promise<AdditionalProfileInfoDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/additional-profile-infos', {
        params: {
          guid: guid
        }
      })
      .then(response => {
        resolve(response.data as AdditionalProfileInfoDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ADDITIONAL_PROFILE_INFOS_FAILED '));
      });
  });
}

export async function getCandidateTagsServer(): Promise<CandidateTagDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/candidate/v1/candidate-tags')
      .then(response => {
        resolve(response.data as CandidateTagDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CANDIDATE_TAGS_FAILED '));
      });
  });
}

export async function getCandidateTagsWithGroupServer(): Promise<GroupCandidateTagDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/candidate/v1/candidate-tags-group')
      .then(response => {
        resolve(response.data as GroupCandidateTagDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CANDIDATE_TAGS_FAILED '));
      });
  });
}

export async function hasMessengerChannelServer(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/messagecenter/v1/hasAnyChannel')
      .then(response => {
        resolve(response.data as boolean);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('HAS_MESSENGER_CHANNEL_FAILED '));
      });
  });
}

export async function updateCandidateTagsServer(
  requestObj: CandidateTagsUpdateRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post(
        'api/candidate/v1/' + requestObj.interviewGuid + '/candidate-tags',
        requestObj.selectedCandidateTags
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CANDIDATE_TAGS_FAILED '));
      });
  });
}

export async function createCandidateTagServer(
  requestObj: CandidateTagCreateRequest
): Promise<CandidateTagDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/candidate/v1/candidate-tags', requestObj)
      .then(response => {
        resolve(response.data as CandidateTagDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CREATE_CANDIDATE_TAG_FAILED '));
      });
  });
}

export async function createCandidateTagAssignGroupServer(
  requestObj: CandidateTagCreateAssignGroupRequest
): Promise<CandidateTagDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/candidate/v1/create-candidatetags-group', requestObj)
      .then(response => {
        resolve(response.data as CandidateTagDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CREATE_CANDIDATE_TAG_FAILED '));
      });
  });
}

export async function selectAllContactsBySearchServer(requestObj: SelectAllContactsAndTagsRequestDto
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/search-contacts/get-all', {
        params: {
          searchTerm: requestObj.searchTerm,
          tagFilterValue: requestObj.tagFilterValue,
          tagFilterCondition: requestObj.tagFilterCondition
        }
      })
      .then(response => {
        resolve(response.data as string[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SELECT_ALL_CONTACTS_FAILED '));
      });
  });
}

export async function copyQuestionnaireServer(requestObj: any): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/questionnaire-copy', {
        params: {
          slug: requestObj.slug,
          title: requestObj.title,
          templateMode: requestObj.templateMode,
          questionnaireType: requestObj.questionnaireType
        }
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('COPY_QUESTIONNAIRE_FAILED '));
      });
  });
}

export async function assignTagsToMultipleAssessmentsServer(
  request: GroupTagAssessmentsRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/assessments/tags/assign-multiple', request)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSESSMENT_RUN_DETAILS_FAILED '));
      });
  });
}

export async function selectAllAssessmentsBySearchServer(
  request: SelectAllAssessmentsRequestDto
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/assessment-run/assessments/get-all', request)
      .then(response => {
        resolve(response.data as string[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SELECT_ALL_CONTACTS_FAILED '));
      });
  });
}

export async function fetchNewContactGroupsServer(): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/messagecenter/v1/newcontactgroups')
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('GET_NEW_CONTACT_GROUPS_FAILED'));
      });
  });
}

export async function updateUserPreferredLanguageServer(
  userPreferredLanguage: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/useradmin/v1/preferred-language/' + userPreferredLanguage)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_PREFERRED_LANGUAGE_FAILED'));
      });
  });
}

export async function getUserPreferredLanguageServer(): Promise<User> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/useradmin/v1/preferred-language')
      .then(response => {
        resolve(response.data as User);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_PREFERRED_LANGUAGE_FAILED'));
      });
  });
}

export async function getDashboardChartReportServer(): Promise<
  StackChartReportResponse
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/reports/v1/dashboard-report')
      .then(response => {
        resolve(response.data as StackChartReportResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_DASHBOARD_REPORT_FAILED'));
      });
  });
}

export async function archiveJobClusterServer(
  jobClusterSlug: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/job-clusters/v1/' + jobClusterSlug + '/archive')
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ARCHIVE_JOB_CLUSTER_FAILED'));
      });
  });
}

export async function archiveAssessmentRunServer(
  assessmentRunSlug: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put(
        'api/messagecenter/v1/assessmentruns/' + assessmentRunSlug + '/archive'
      )
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ARCHIVE_ASSESSMENT_RUN_FAILED'));
      });
  });
}

export async function archiveQuestionnaireServer(
  questionnaireSlug: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put(
        'api/messagecenter/v1/questionnaire/' + questionnaireSlug + '/archive'
      )
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ARCHIVE_QUESTIONNAIRE_FAILED'));
      });
  });
}

export async function fetchJobClustersFilterResultsServer(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get(
        'api/reports/v1/filter/job-clusters?tenantIds=' + searchRequest.tenants
      )
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_CLUSTERS_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchUtmTagsFilterResultsServer(
  searchRequest: FunnelReportRequest
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/utm-tags?tenantIds=' + searchRequest.tenants)
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UTM_TAGS_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function getJobClusterServer(
  request: JobClusterRequest
): Promise<JobClusterItem> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get(
        '/api/job-clusters/v1/' +
          request.jobClusterSlug +
          '?language=' +
          request.languageCode
      )
      .then(response => {
        resolve(response.data as JobClusterItem);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_CLUSTER_FAILED'));
      });
  });
}

export async function getBulkMessagesServer(
  request: BulkMessageSearchRequestDto
): Promise<BulkMessageSearchResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/bulk-messages/v1/messages', request)
      .then(response => {
        resolve(response.data as BulkMessageSearchResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_BULK_MESSAGES_FAILED'));
      });
  });
}

export async function getJobUrlsByGroupServer(
  request: JobUrlByGroupRequestDto
): Promise<JobUrl[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/joburls/v1/group', request)
      .then(response => {
        resolve(response.data as JobUrl[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 401) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_URLS_FAILED'));
      });
  });
}

export async function getBulkMessageRecipientsServer(
  request: BulkMessageRecipientSearchRequestDto
): Promise<BulkMessageRecipientResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/bulk-messages/v1/recipients', request)
      .then(response => {
        resolve(response.data as BulkMessageRecipientResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_BULK_MESSAGE_RECIPIENTS_FAILED'));
      });
  });
}

export async function updateJobOriginsServer(
  request: JobOriginRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/jobadministration/v1/origins/' + request.jobSlug, request)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_JOB_ORIGINS_FAILED'));
      });
  });
}

export async function getMediaMetaDataServer(
  mediaGuid: string
): Promise<MediaMetaDataDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/media/v1/meta-data/' + mediaGuid)
      .then(response => {
        resolve(response.data as MediaMetaDataDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_MEDIA_META_DATA_FAILED'));
      });
  });
}

export async function selectAllRecipientsBySearchServer(
  request: SelectAllRecipientsRequestDto
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/bulk-messages/v1/recipients/get-all', request)
      .then(response => {
        resolve(response.data as string[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SELECT_ALL_RECIPIENTS_FAILED '));
      });
  });
}

export async function assignTagsToMultipleRecipientsServer(
  request: GroupTagRecipientsRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/bulk-messages/v1/recipients/tags/assign-multiple', request)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ASSIGN_TAGS_FAILED '));
      });
  });
}

export async function deleteBulkMessageRecipientServer(
  recipientSlug: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .delete('/api/bulk-messages/v1/recipients/' + recipientSlug)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DELETE_BULK_MESSAGE_RECIPIENT_FAILED'));
      });
  });
}

export async function getNotificationInfoServer(
  interviewGuid: string,
  slug: string
): Promise<NotificationInfoDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/messagecenter/v1/notification-info/' + interviewGuid, {
        params: {
          slug: slug
        }
      })
      .then(response => {
        resolve(response.data as NotificationInfoDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ASSIGN_TAGS_FAILED '));
      });
  });
}

export async function addParticipantsServer(
  requestObj: AddParticipantsDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/messagecenter/v1/assessmentrun/add-participants', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ADD_PARTICIPANTS_FAILED '));
      });
  });
}

export async function addAssessmentGroupsServer(
  requestObj: AddAssessmentGroupDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/messagecenter/v1/assessmentrun/add-groups', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ADD_ASSESSMENT_GROUPS_FAILED '));
      });
  });
}

export async function getAssessmentUserGroupsServer(): Promise<
  FilterResponseDto[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/messagecenter/v1/assessmentrun/groups')
      .then(response => {
        resolve(response.data as FilterResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSESSMENT_GROUPS_FAILED '));
      });
  });
}

export async function updateContactGroupServer(
  requestObj: UpdateContactGroupDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/messagecenter/v1/contact/group', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CONTACT_GROUP_FAILED '));
      });
  });
}

export async function exportAssessmentsServer(
  assessmentrunSlug: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get(
        '/api/messagecenter/v1/assessmentrun/' + assessmentrunSlug + '/export',
        {
          responseType: 'arraybuffer'
        }
      )
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_EXCEL_EXPORT_FAILED'));
      });
  });
}

export async function updateDeleteAfterDateServer(
  requestObj: DleteAfterDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/interview/v1/delete-after', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_DELETE_AFTER_DATE_FAILED'));
      });
  });
}

export async function permanentDeleteApplicantServer(
  requestObj: DleteAfterDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/interview/v1/delete-multiple', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('PERMANENT_DELETE_APPLICANTS_FAILED'));
      });
  });
}

export async function updateInterviewParamsServer(
  requestObj: InterviewParamsDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/interview/v1/interview-params', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_INTERVIEW_PARAMS_FAILED'));
      });
  });
}

export async function updateCandidateSalutationServer(
  requestObj: CandidateSalutationDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/recruiter-interview/v1/candidate-salutation', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_INTERVIEW_SALUTATION_FAILED'));
      });
  });
}

export async function wipeOutTenantServer(
  requestObj: WipeOutAuthCredentialsDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/tenant/wipe-out/v1', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('WIPE_OUT_TENANT_FAILED'));
      });
  });
}

export async function deactivateTenantServer(
  requestObj: DeactivateTenantDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/tenantconfig/v1/deactivateTenant', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DEACTIVATE_TENANT_FAILED'));
      });
  });
}

export async function updateCandidateLanguageServer(
  requestObj: CandidateLanguageDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/recruiter-interview/v1/candidate-language', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_INTERVIEW_LANGUAGE_FAILED'));
      });
  });
}

export async function assignTagsToMultiplecandidatesServer(
  request: GroupTagCandidatesRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/candidate/v1/candidate-tags/assign-multiple', request)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ASSESSMENT_RUN_DETAILS_FAILED '));
      });
  });
}

export async function selectAllInterviewGuidsServer(
  requestObj: ApplicantSearchRequest
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/applicantssearch/v1/get-all', requestObj)
      .then(response => {
        resolve(response.data as string[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SELECT_ALL_INTERVIEW_GUIDS_FAILED'));
      });
  });
}

export async function loadtemplatesServer(
  requestObj: LoadTemplatesRequest
): Promise<ChannelTemplateMessageDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/templates', {
        params: {
          interviewGuid: requestObj.interviewGuid,
          chatTrigger: requestObj.chatTrigger
        }
      })
      .then(response => {
        resolve(response.data as ChannelTemplateMessageDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('LOAD_TEMPLATES_FAILED'));
      });
  });
}

export async function loadtemplateCandidateServer(
  requestObj: LoadTemplatesRequest
): Promise<ChannelTemplateMessageDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/template-candidate', {
        params: {
          interviewGuid: requestObj.interviewGuid,
          chatTrigger: requestObj.chatTrigger,
          candidateLanguageCode: requestObj.candidateLanguageCode
        }
      })
      .then(response => {
        resolve(response.data as ChannelTemplateMessageDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('LOAD_TEMPLATE_CANDIDATE_FAILED'));
      });
  });
}

export async function loadRemoteSystemParamsServer(
  remoteSystemType: string
): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantconfig/v1/remote-system-params/' + remoteSystemType)
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('LOAD_REMOTE_SYSTEM_PARAMS_FAILED'));
      });
  });
}

export async function updateCandidateTagNameServer(
  requestObj: TagMangementDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/candidate/v1/candidate-tags', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CANDIDATE_TAG_NAME_FAILED '));
      });
  });
}

export async function deleteCandidateTagNameServer(
  requestObj: TagMangementDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .delete('api/candidate/v1/candidate-tags/' + requestObj.value)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CANDIDATE_TAG_NAME_FAILED '));
      });
  });
}

export async function getTenantGroupsServer(): Promise<FilterResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/groups')
      .then(response => {
        resolve(response.data as FilterResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_GROUPS_FAILED'));
        }
      });
  });
}

export async function getRoleBasedGroupsServer(): Promise<FilterResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/role-user-groups')
      .then(response => {
        resolve(response.data as FilterResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_GROUPS_FAILED'));
        }
      });
  });
}

export async function getUserGroupsServer(): Promise<FilterResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenantadmin/v1/user-groups')
      .then(response => {
        resolve(response.data as FilterResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_GROUPS_FAILED'));
        }
      });
  });
}

export async function getJobTemplateGroupsServer(): Promise<
  StepTemplateGroup[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/job/interview/template-groups')
      .then(response => {
        resolve(response.data as StepTemplateGroup[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_JOB_STEP_TEMPLATES_FAILED'));
        }
      });
  });
}

export async function getContactsImportHistoryServer(
  searchRequest: ContactsImportSearchRequest
): Promise<ContactsImportResultDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/contacts-import/v1/history', searchRequest)
      .then(response => {
        resolve(response.data as ContactsImportResultDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_CONTACTS_IMPORT_HISTORY_FAILED'));
        }
      });
  });
}

export async function deleteContactsImportServer(slug: string): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .delete('api/contacts-import/v1/history/' + slug)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('DELETE_CONTACTS_IMPORT_HISTORY_FAILED'));
        }
      });
  });
}

export async function updateClusterJobsServer(
  requestObj: ClusterJobsUpdateDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post(
        'api/job-clusters/v1/' + requestObj.clusterSlug,
        requestObj.clusterJobs
      )
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('SAVE_CLUSTER_JOBS_FAILED'));
        }
      });
  });
}

export async function multipleStatusTransitionServer(
  requestObj: TransitionStatusRequest[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/recruiter-interview/v1/status-transition/multiple', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('MULTIPLE_INTERVIEW_STATUS_TRANSITION_FAILED'));
      });
  });
}

export async function fetchAllAlternativeGroupsServer(
  jobSlug: string
): Promise<GroupsResponseDto> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('api/tenantadmin/v1/alternativeGroups/job/' + jobSlug)
      .then(response => {
        resolve(response.data as GroupsResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('ALTERNATIVE_GROUP_LOAD_ERROR'));
      });
  });
}

export async function updateMultipleCandidatesGroupServer(
  requestObj: MultipleGroupTransitionRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/recruiter-interview/v1/group-transition/multiple', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          reject(new Error('TOKEN_ERROR'));
        } else {
          reject(new Error('ERROR_OCCURRED'));
        }
      });
  });
}

export async function getConversationReportsServer(): Promise<
  ConversationReportsResponseDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/reports/conversation/v1/monthly')
      .then(response => {
        resolve(response.data as ConversationReportsResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          reject(new Error('TOKEN_ERROR'));
        } else {
          reject(new Error('ERROR_OCCURRED'));
        }
      });
  });
}

export async function getSingleContactServer(
  interviewGuid: string
): Promise<AllChatMessageResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/messagecenter/v1/single-contact/' + interviewGuid)
      .then(response => {
        resolve(response.data as AllChatMessageResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SINGLE_CONTACT_FAILED'));
      });
  });
}

export async function getRemoteSystemServer(): Promise<
  RemoteSystemResponseDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/remote-system')
      .then(response => {
        resolve(response.data as RemoteSystemResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REMOTE_SYSTEM_FAILED'));
      });
  });
}

export async function getRemoteSystemTypesServer(): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/remote-system-types')
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_REMOTE_SYSTEM_TYPES_FAILED'));
      });
  });
}

export async function updateRemoteSystemServer(
  requestObj: RemoteSystemRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/remote-system', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_REMOTE_SYSTEM_FAILED'));
      });
  });
}

export async function getLanguageReportServer(
  requestObj: FunnelReportRequest
): Promise<PieChartResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/reports/language/v1', requestObj)
      .then(response => {
        resolve(response.data as PieChartResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_LANGUAGE_REPORT_FAILED'));
      });
  });
}

export async function getJobClusterUrlsByGroupServer(
  request: JobClusterUrlByGroupRequestDto
): Promise<JobClusterUrlItem[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/job-clusters/v1/urls/group', request)
      .then(response => {
        resolve(response.data as JobClusterUrlItem[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 401) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_CLUSTER_URLS_FAILED'));
      });
  });
}

export async function updateJobClusterOriginsServer(
  request: JobClusterOriginsRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/job-clusters/v1/origins', request)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_JOB_CLUSTER_ORIGINS_FAILED'));
      });
  });
}

export async function getInfoNoteServer(): Promise<InfoNoteDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/infonote/v1')
      .then(response => {
        resolve(response.data as InfoNoteDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_INFO_NOTE_FAILED'));
      });
  });
}

export async function updateInfoNoteServer(): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/infonote/update/v1')
      .then(response => {
        resolve(response.data as InfoNoteDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_INFO_NOTE_FAILED'));
      });
  });
}

export async function getCampaignSettingsServer(
  jobSlug: string
): Promise<JAWConfig> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/campaigns/v1/campaign/app-props', {
        params: {
          jobSlug: jobSlug
        }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_APP_PROPS_JOB_CONFIG_FAILED'));
      });
  });
}

export async function saveCampaignInfoServer(
  requestObj: CampaignInfoRequestDto
): Promise<CampaignInfoResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign', requestObj)
      .then(response => {
        resolve(response.data as CampaignInfoResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_CAMPAIGN_INFO_FAILED'));
      });
  });
}

export async function getCampaignsServer(
  requestObj: CampaignRequestDto
): Promise<CampaignResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1', requestObj)
      .then(response => {
        resolve(response.data as CampaignResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CAMPAIGNS_FAILED'));
      });
  });
}

export async function updateCampaignSettingsServer(
  requestObj: JAWConfig
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign/app-props', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CAMPAIGN_SETTINGS_FAILED'));
      });
  });
}

export async function saveCampaignSitesServer(
  requestObj: JobInfoRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign/sites', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_CAMPAIGN_SITES_FAILED'));
      });
  });
}

export async function saveCampaignOriginsServer(
  requestObj: JobInfoRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign/origins', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_CAMPAIGN_ORIGINS_FAILED'));
      });
  });
}

export async function getCampaignInfoServer(
  campaignSlug: string
): Promise<CampaignInfoResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/campaigns/v1/campaign/' + campaignSlug)
      .then(response => {
        resolve(response.data as CampaignInfoResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CAMPAIGN_INFO_FAILED'));
      });
  });
}

export async function saveCampaignQuestionnaireServer(
  requestObj: CampaignQuestionnaireRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('api/campaigns/v1/campaign/questionnaire', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_CAMPAIGN_QUESTIONNAIRE_FAILED'));
      });
  });
}

export async function getCampaignInterviewServer(
  campaignSlug: string
): Promise<JAWInterviewRequest> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/campaigns/v1/campaign/' + campaignSlug + '/interview')
      .then(response => {
        resolve(response.data as JAWInterviewRequest);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_CAMPAIGN_INTERVIEW_FAILED'));
      });
  });
}

export async function updateCampaignInterviewServer(
  requestObj: JAWInterviewRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign/interview', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_CAMPAIGN_INTERVIEW_FAILED'));
      });
  });
}

export async function getCampaignTemplateGroupsServer(): Promise<
  StepTemplateGroup[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/campaigns/v1/campaign/interview/template-groups')
      .then(response => {
        resolve(response.data as StepTemplateGroup[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_CAMPAIGN_STEP_TEMPLATES_FAILED'));
        }
      });
  });
}

export async function saveCampaignStatusChangeServer(
  requestObj: CampaignStatusChangeRequest
): Promise<JobStatusChangeResponse> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign/updateStatus', requestObj)
      .then(response => {
        resolve(response.data as JobStatusChangeResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CAMPAIGN_STATUS_CHANGE_FAILED'));
      });
  });
}

export async function saveInterviewQuestionsAsQuestionnaireServer(
  requestObj: CampaignQuestionnaireTemplateDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/campaigns/v1/campaign/interview/questionnaire', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_AS_TEMPLATE_FAILED'));
      });
  });
}

export async function getOriginReportServer(
  requestObj: FunnelReportRequest
): Promise<PieChartResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/reports/v1/origin-report', requestObj)
      .then(response => {
        resolve(response.data as PieChartResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_ORIGIN_REPORT_FAILED'));
      });
  });
}

export async function getExternalTrackingTypesServer(): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/external-tracking-types')
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_EXTERNAL_TRACKING_TYPES_FAILED'));
      });
  });
}

export async function loadExternalTrackingParamsServer(
  externalTrackingType: string
): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/external-tracking-params/' + externalTrackingType)
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('LOAD_EXTERNAL_TRACKING_PARAMS_FAILED'));
      });
  });
}

export async function getTrackingEventTypesServer(): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/tracking-event-types')
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_TRACKING_EVENT_TYPES_FAILED'));
      });
  });
}

export async function getProductTypesServer(): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/product-types')
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_TRACKING_EVENT_TYPES_FAILED'));
      });
  });
}

export async function getExternalTrackingServer(): Promise<
  ExternalTrackingDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/external-tracking')
      .then(response => {
        resolve(response.data as ExternalTrackingDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_EXTERNAL_TRACKING_FAILED'));
      });
  });
}

export async function saveExternalTrackingServer(
  input: ExternalTrackingDto
): Promise<ExternalTrackingDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/external-tracking', input)
      .then(response => {
        resolve(response.data as ExternalTrackingDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_EXTERNAL_TRACKING_FAILED'));
      });
  });
}

export async function updateMessengerChannelServer(
  input: MessengerChannelDto
): Promise<MessengerChannelDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/update-messenger-channel', input)
      .then(response => {
        resolve(response.data as MessengerChannelDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_MESSENGER_CHANNEL_FAILED'));
      });
  });
}

export async function archiveMessengerChannelServer(
  input: MessengerChannelDto
): Promise<MessengerChannelDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/archive-messenger-channel', input)
      .then(response => {
        resolve(response.data as MessengerChannelDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('ARCHIVE_MESSENGER_CHANNEL_FAILED'));
      });
  });
}

export async function deleteActionProtocolCommentServer(
  commentSlug: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .delete('api/comment/v1/' + commentSlug)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('DELETE_COMMENT_FAILED '));
      });
  });
}

export async function getCandidateAgeReportServer(
  requestObj: FunnelReportRequest
): Promise<PieChartResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/reports/v1/age-report', requestObj)
      .then(response => {
        resolve(response.data as PieChartResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_AGE_REPORT_FAILED'));
      });
  });
}

export async function fetchExternalJobInfosServer(
  tenantIds: number[]
): Promise<ExternalJobInfoResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/external-job-info?tenantIds=' + tenantIds)
      .then(response => {
        resolve(response.data as ExternalJobInfoResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('EXTERNAL_JOB_INFO_RESPONSE_ERROR'));
      });
  });
}

export async function fetchPostingJobIdsServer(
  tenantIds: number[]
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/posting-job-info?tenantIds=' + tenantIds)
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('POSTING_JOB_IDS_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchPostingJobTitlesServer(
  tenantIds: number[]
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/posting-job-titles?tenantIds=' + tenantIds)
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('POSTING_JOB_TITLES_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchExternalCompanyNamesServer(
  tenantIds: number[]
): Promise<ReportsFilterResponse[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/filter/company-names?tenantIds=' + tenantIds)
      .then(response => {
        resolve(response.data as ReportsFilterResponse[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('EXTERNAL_COMPANY_NAMES_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function fetchJobPostingSiteServer(): Promise<
  JobPostingSitesCountDto
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/job-posting-sites')
      .then(response => {
        resolve(response.data as JobPostingSitesCountDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('JOB_POSTING_SITES_FILTER_RESPONSE_ERROR'));
      });
  });
}

export async function updateInterviewSitesServer(
  requestObj: string
): Promise<UpdateInterviewSitesResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get(
        'api/recruiter-interview/v1/assign-sites?interviewGuid=' + requestObj
      )
      .then(response => {
        resolve(response.data as UpdateInterviewSitesResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_INTERVIEW_SITES_ERROR'));
      });
  });
}

export async function updateUserPreferredChatLanguageServer(
  userPreferredChatLanguage: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .put('/api/useradmin/v1/chat-language/' + userPreferredChatLanguage)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_PREFERRED_CHAT_LANGUAGE_FAILED'));
      });
  });
}

export async function getUserPreferredChatLanguageServer(): Promise<string> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/useradmin/v1/chat-language')
      .then(response => {
        resolve(response.data as string);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_PREFERRED_CHAT_LANGUAGE_FAILED'));
      });
  });
}

export async function getMessageServer(slug: string): Promise<ChatMessage> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/chat/v1/message', {
        params: {
          slug: slug
        }
      })
      .then(response => {
        resolve(response.data as ChatMessage);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_MESSAGE_FAILED'));
      });
  });
}

export async function getYesTextServer(
  userPreferredChatLanguage: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/messagecenter/v1/get-yes-text', {
        params: {
          language: userPreferredChatLanguage
        }
      })
      .then(response => {
        resolve(response.data as string);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_YES_TEXT_FAILED'));
      });
  });
}

export async function copyJobTemplateServer(
  jobTemplateSlug: string
): Promise<CopyJobTemplateResult> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/jobtemplate/v1/copy/' + jobTemplateSlug)
      .then(response => {
        resolve(response.data as CopyJobTemplateResult);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('COPY_JOB_TEMPLATE_FAILED'));
      });
  });
}

export async function updateUserTfaServer(requestObj: User): Promise<User> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/useradmin/v1/update-tfa', requestObj)
      .then(response => {
        resolve(response.data as User);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TFA_CODE_ERROR'));
          }
        }
        reject(new Error('UPDATE_USER_TFA_FAILED'));
      });
  });
}

export async function subscribeToInterviewServer(
  request: UserSubscribedInterviewDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/messagecenter/v1/interview/subscribe', request)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SUBSCRIBE_TO_INTERVIEW_FAILED'));
      });
  });
}

export async function getOnboardTenantByDealIdServer(
  dealId: string
): Promise<OnboardNewTenantResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('/api/onboard/tenant/v1/deal/' + dealId)
      .then(response => {
        resolve(response.data as OnboardNewTenantResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('GET_ONBOARD_TENANT_FAILED'));
      });
  });
}

export async function getOnboardTenantByContactIdServer(
  contactId: string
): Promise<OnboardNewTenantResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('/api/onboard/tenant/v1/contact/' + contactId)
      .then(response => {
        resolve(response.data as OnboardNewTenantResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('CONTACT_ID_NOT_FOUND'));
          }
        }
        reject(new Error('GET_ONBOARD_TENANT_FAILED'));
      });
  });
}

export async function saveOnboardTenantServer(
  requestObj: OnboardNewTenantRequest
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('/api/onboard/tenant/v1', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('POST_ONBOARD_TENANT_FAILED'));
      });
  });
}

export async function resetOnboardTenantServer(
  dealId: string
): Promise<OnboardNewTenantResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .put('/api/onboard/tenant/v1/reset/' + dealId)
      .then(response => {
        resolve(response.data as OnboardNewTenantResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('RESET_ONBOARD_TENANT_FAILED'));
      });
  });
}

export async function finalizeOnboardTenantServer(
  dealId: string
): Promise<OnboardNewTenantResponse> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .put('/api/onboard/tenant/v1/finalize/' + dealId)
      .then(response => {
        resolve(response.data as OnboardNewTenantResponse);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('FINALIZE_ONBOARD_TENANT_FAILED'));
      });
  });
}

export async function sendWabaSetupInviteServer(
  requestObj: StartWabaSetupRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('/api/onboard/tenant/v1/waba-invite', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('SEND_WABA_SETUP_INVITE_FAILED'));
      });
  });
}

export async function startOnboardTenantServer(dealId: string): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .put('/api/onboard/tenant/v1/start-onboard/' + dealId)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('START_ONBOARD_TENANT_FAILED'));
      });
  });
}

export async function getDefaultJobDescriptionServer(
  languageCode: string
): Promise<DefaultJobDescriptionDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/jobadministration/v1/job/job-description', {
        params: {
          languageCode: languageCode
        }
      })
      .then(response => {
        resolve(response.data as DefaultJobDescriptionDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('GET_DEFAULT_JOB_DESCRIPTION_FAILED'));
        }
      });
  });
}

export async function sendTfaCodeserver(): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/useradmin/v1/send-tfa')
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('SEND_TFA_CODE_FAILED'));
        }
      });
  });
}

export async function verifyTfaCodeserver(
  tfaCode: string
): Promise<TFAVerificationDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/useradmin/v1/verify-tfa', {
        params: {
          tfaCode: tfaCode
        }
      })
      .then(response => {
        resolve(response.data as TFAVerificationDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('VERIFY_TFA_CODE_FAILED'));
        }
      });
  });
}
export async function getMessengerChannels(): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/messenger-channels', {
        params: {}
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('MESSENGER_CHANNEL_LOADING_FAILED'));
        }
      });
  });
}

export async function getChannelDetails(channelId: string): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/channel-details', {
        params: {
          channelId: channelId
        }
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('MESSENGER_CHANNEL_LOADING_FAILED'));
        }
      });
  });
}

export async function translateTextServer(
  requestObj: TranslationTextDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/masterdata/v1/template-translations', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CUSTOM_TEMPLATE_TEXT_TRANSLATION_FAILED'));
      });
  });
}

export async function translatePropTextServer(
  requestObj: TranslationPropTextDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/masterdata/v1/proptext-translations', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('TEXT_TRANSLATION_FAILED'));
      });
  });
}

export async function translateChatTextServer(
  requestObj: TranslationPropTextDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/messagecenter/v1/detectlanguage-translate', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CHAT_TEXT_TRANSLATION_FAILED'));
      });
  });
}

export async function saveCustomTemplateServer(
  requestObj: CreateTemplateMessageDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/masterdata/v1/create-message-template', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('CREATE_CUSTOM_TEMPLATE_FAILED'));
      });
  });
}

export async function getCustomTemplateLabelServer(): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/masterdata/v1/custom-message-template-labels', {
        params: {}
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('CUSTOM_TEMPLATE_LABLES_LOADING_FAILED'));
        }
      });
  });
}

export async function loadJobsForExternalChatServer(
  extId: string
): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('/api/embeddedchat/v1/loadJobsForCandidate', {
        params: { extId: extId }
      })
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('LOAD_JOBS_FOR_CANDIDATE_FAILED'));
        }
      });
  });
}

export async function loadTenantsForExternalChatServer(
  extId: string
): Promise<ILabelValue[]> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .get('/api/embeddedchat/v1/loadTenantsForCandidate', {
        params: { extId: extId }
      })
      .then(response => {
        resolve(response.data as ILabelValue[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('LOAD_TENANTS_FOR_CANDIDATE_FAILED'));
        }
      });
  });
}

export async function startChatAfterJobSelectionServer(
  extId: string,
  guid: string,
  identifier: string
): Promise<StartChatResponseDto> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('/api/embeddedchat/v1/startChat', {
        extId: extId,
        guid: guid,
        identifier: identifier
      })
      .then(response => {
        resolve(response.data as StartChatResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        } else {
          reject(new Error('START_CHAT_AFTER_JOB_SELECTION_FAILED'));
        }
      });
  });
}

export async function isSuperAdminATSLoginAllowedServer(
  guid: string
): Promise<SuperAdminATSLoginDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/tenant/v1/super-admin/' + guid)
      .then(response => {
        resolve(response.data as SuperAdminATSLoginDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_SUPER_ADMIN_LOGIN_FAILED'));
      });
  });
}

export async function getJobTemplateStepsServer(
  templateSlug: string
): Promise<JAWStepTemplate[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/jobadministration/v1/job-template/' + templateSlug + '/steps')
      .then(response => {
        resolve(response.data as JAWStepTemplate[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_JOB_TEMPLATE_STEPS_FAILED'));
      });
  });
}

export async function getAdCampaignsServer(
  requestObj: AdCampaignRequestDto
): Promise<AdCampaignResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/ad-campaigns/v1', requestObj)
      .then(response => {
        resolve(response.data as AdCampaignResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_AD_CAMPAIGNS_FAILED'));
      });
  });
}

export async function getAdCampaignJobsServer(): Promise<
  AdCampaignJobsResponseDto[]
> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/ad-campaigns/v1/jobs')
      .then(response => {
        resolve(response.data as AdCampaignJobsResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_AD_CAMPAIGN_JOBS_FAILED'));
      });
  });
}

export async function getAdCampaignJobSitesServer(
  jobSlug: string
): Promise<AdCampaignJobSitesResponseDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/ad-campaigns/v1/jobs/' + jobSlug + '/sites')
      .then(response => {
        resolve(response.data as AdCampaignJobSitesResponseDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_AD_CAMPAIGN_JOB_SITES_FAILED'));
      });
  });
}

export async function saveAdCampaignInfoServer(
  requestObj: AdCampaignInfoRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/ad-campaigns/v1/info', requestObj)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_AD_CAMPAIGN_INFO_FAILED'));
      });
  });
}

export async function getAdCampaignInfoServer(
  referenceId: string
): Promise<AdCampaignInfoResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/ad-campaigns/v1/' + referenceId + '/info')
      .then(response => {
        resolve(response.data as AdCampaignInfoResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 403) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_AD_CAMPAIGN_JOB_INFO_FAILED'));
      });
  });
}

export async function checkRemoteSystemParamsServer(
  requestObj: OnboardRemoteSystemParamsRequestDto
): Promise<OnboardRemoteSystemParamsCheckDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post(
        '/api/onboard/tenant/v1/onboard-remote-system-params-change',
        requestObj
      )
      .then(response => {
        resolve(response.data as OnboardRemoteSystemParamsCheckDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 404) {
            reject(new Error('DEAL_ID_NOT_FOUND'));
          }
        }
        reject(new Error('CHECK_REMOTE_SYSTEM_PARAMS_FAILED'));
      });
  });
}

export async function fetchKleinanzeigenJobPostingsServer(
  requestObj: KleinAnzeigenPostingRequestDto
): Promise<KleinAnzeigenPostingResponseDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/reports/v1/ka-postings', requestObj)
      .then(response => {
        resolve(response.data as KleinAnzeigenPostingResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('KLEINANZEIGEN_JOB_POSTINGS_RESPONSE_ERROR'));
      });
  });
}

export async function saveDefaultTextServer(
  requestObj: JAWPropertyConfig
): Promise<JAWPropertyConfig> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/masterdata/v1/default-text', requestObj)
      .then(response => {
        resolve(response.data as JAWPropertyConfig);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('SAVE_JOB_CONFIG_FAILED'));
      });
  });
}

export async function getFinalizeAtDateServer(guid: string): Promise<string> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/recruiter-interview/v1/finalize-at-date/' + guid)
      .then(response => {
        resolve(response.data as string);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_FINALYZE_AT_DATE_FAILED'));
      });
  });
}

export async function updateMessageTextServer(
  requestDto: BulkMessageRequestDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('api/bulk-messages/v1/update-message-text', requestDto)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('UPDATE_MESSAGE_TEXT_FAILED '));
      });
  });
}

export async function processPagePermissionServer(
  requestDto: MetaLeadAuthCodeDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .post('/api/meta-lead-ad/v1/page-permission', requestDto)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('PROCESS_PAGE_PERMISSION_FAILED '));
      });
  });
}


export async function getPagePermissionsServer(): Promise<PagePermissionDto[]> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('/api/meta-lead-ad/v1/page-permission')
      .then(response => {
        resolve(response.data as PagePermissionDto[]);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('GET_PAGE_PERMISSION_FAILED'));
      });
  });
}

export async function uploadStartMessageMediaServer(
  formData: Object
): Promise<any> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/masterdata/v1/start-message-media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('UPLOAD_START_MESSAGE_MEDIA_ERROR'));
      });
  });
}

export async function deleteStartMessageMediaServer(): Promise<any> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .delete('api/masterdata/v1/start-message-media')
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('DELETE_START_MESSAGE_MEDIA_ERROR'));
      });
  });
}

export async function uploadStartMessageMediaForJobServer(
  requestObj: StartMessageMediaJobRequest
): Promise<any> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('/api/jobadministration/v1/job/' + requestObj.jobSlug + '/start-message-media', requestObj.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('UPLOAD_START_MESSAGE_MEDIA_ERROR'));
      });
  });
}

export async function deleteStartMessageMediaForJobServer(jobSlug: string): Promise<any> {
  setJWT(SessionStorage.getItem('app-token') || '');
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .delete('/api/jobadministration/v1/job/' + jobSlug + '/start-message-media')
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('DELETE_START_MESSAGE_MEDIA_ERROR'));
      });
  });
}

export async function applyAiDisclaimerServer(
  request: AiDisclaimerRequestDto
): Promise<AiDisclaimerResponseDto> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/shorturl/v1/apply-ai-disclaimer', request)
      .then(response => {
        resolve(response.data as AiDisclaimerResponseDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
        }
        reject(new Error('ACCEPT_AI_DISCLAIMER_FAILED'));
      });
  });
}

export async function fetchBenchmarkDataServer(): Promise<BenchmarkDataDto> {
  return new Promise((resolve, reject) => {
    setJWT(SessionStorage.getItem('app-token') || '');
    pitchYouDataAPi
      .get('api/reports/v1/benchmark-data')
      .then(response => {
        resolve(response.data as BenchmarkDataDto);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 400) {
            reject(new Error('TOKEN_ERROR'));
          }
        }
        reject(new Error('BENCHMARK_DATA_ERROR'));
      });
  });
}

export async function cloneTenantServer(
  request: CloneTenantDto
): Promise<any> {
  return new Promise((resolve, reject) => {
    pitchYouDataAPi
      .post('api/tenantconfig/v1/clone-tenant', request)
      .then(response => {
        resolve(response.data as any);
      })
      .catch(error => {
        if (error && error.response) {
          console.log(error.response);
          if (error.response.status && error.response.status === 409) {
            reject(new Error('TENANT_EXISTS_ERROR'));
          }
        }
        reject(new Error('TENANT_DUPLICATION_FAILED'));
      });
  });
}