import store from '@/store';
import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';;
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';
import {
  clearJWT,
  doChangePassword,
  doResetPassword,
  doSaveNewPassword,
  doSendTFACode,
  doUpdateUserTfa,
  doValidateNewPasswordToken,
  doVerifyTFACode,
  getInfoNote,
  getRemoteSystem,
  getRemoteSystemTypes,
  getUserPreferredLanguage,
  isCred,
  performLogin,
  updateInfoNote,
  updateUserCredential,
  updateUserNotifications,
  updateUserPreferredLanguage
} from '../api';
import {
  ChangableEntitlement,
  ChangePasswordRequest,
  CredentialType,
  ILabelValue,
  InfoNoteDto,
  LoginRequest,
  NewPasswordRequest,
  RemoteSystemResponseDto,
  ResetPasswordRequest,
  RoleLevelType,
  UpdateCredentialRequest,
  User
} from '../models';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'userModule',
  store
})
class UserModule extends VuexModule {
  public user: User | null = null;

  get isLoggedIn(): boolean {
    return this.user !== null;
  }

  @Mutation
  public setUser(user: User) {
    this.user = user;
    if (user) {
      SessionStorage.remove('app-user');
      SessionStorage.set('app-user', JSON.stringify(user));
    }
  }

  @Action({ commit: 'setUser', rawError: true })
  public async login(loginRequest: LoginRequest) {
    try {
      const user = await performLogin(loginRequest);
      if (user) {
        SessionStorage.set('app-user', JSON.stringify(user));
      }
      return user;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ commit: 'setUser' })
  public resetUser() {
    return null;
  }

  @Action({ commit: 'setUser' })
  public logoutUser() {
    SessionStorage.remove('app-user');
    SessionStorage.remove('app-token');
    SessionStorage.remove('app-joblist');
    SessionStorage.remove('app-job-dashboard-details');
    SessionStorage.remove('app-job-dashboard-interviews');
    clearJWT();
    return null;
  }

  @Action({ rawError: true })
  public async performChangePassword(
    changePasswordRequest: ChangePasswordRequest
  ) {
    try {
      const result = await doChangePassword(changePasswordRequest);
      return result;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async performNotificationsChange(user: User) {
    try {
      const apiResponse = await updateUserNotifications(user);
      return apiResponse;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async performResetPassword(
    resetPasswordRequest: ResetPasswordRequest
  ) {
    try {
      const result = await doResetPassword(resetPasswordRequest);
      return result;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async performValidateNewPasswordToken(
    validateNewPasswordTokenRequest: NewPasswordRequest
  ) {
    try {
      const result = await doValidateNewPasswordToken(
        validateNewPasswordTokenRequest
      );
      return result;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async performSaveNewPassword(
    newPasswordTokenRequest: NewPasswordRequest
  ) {
    try {
      const result = await doSaveNewPassword(newPasswordTokenRequest);
      return result;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateUserCredential(request: UpdateCredentialRequest) {
    try {
      updateUserCredential(request);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public isGroupAdmin(): boolean | null {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    switch (this.loggedInUser.roleLevel) {
      case 'ADMIN':
        return true;
      case 'SUPERADMIN':
        return true;
      default:
    }

    return this.loggedInUser.userAdminRole != 'NONE';
  }

  @Action({ rawError: true })
  public isReaderAccess(): boolean | null {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    switch (this.loggedInUser.roleLevel) {
      case 'ADMIN':
        return false;
      case 'SUPERADMIN':
        return false;
      default:
    }

    return this.loggedInUser.userAdminRole == 'READER';
  }

  @Action({ rawError: true })
  public isRootGroupAdmin(): boolean {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    switch (this.loggedInUser.roleLevel) {
      case 'ADMIN':
        return true;
      case 'SUPERADMIN':
        return true;
      default:
    }
    return this.loggedInUser.userAdminRole == 'ALL';
  }
  @Action({ rawError: true })
  public isUserAdmin(): boolean {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    switch (this.loggedInUser.roleLevel) {
      case 'ADMIN':
        return false;
      case 'SUPERADMIN':
        return false;
      default:
    }
    return this.loggedInUser.userAdminRole == 'USER_ADMIN';
  }
  @Action({ rawError: true })
  public isCred(reqLevel: RoleLevelType): boolean | null {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    switch (reqLevel) {
      case 'RECRUITER_WRITE':
        switch (this.loggedInUser.roleLevel) {
          case 'RECRUITER_READ':
            return false;
          default:
            return true;
        }
      case 'ADMIN':
        switch (this.loggedInUser.roleLevel) {
          case 'RECRUITER_READ':
            return false;
          case 'RECRUITER_WRITE':
            return false;
          case 'SUPERADMIN':
            return false;
          default:
            return true;
        }
      case 'SUPERADMIN':
        switch (this.loggedInUser.roleLevel) {
          case 'RECRUITER_READ':
            return false;
          case 'RECRUITER_WRITE':
            return false;
          case 'ADMIN':
            return false;
          default:
            return true;
        }

      default:
        return false;
    }
  }

  @Action({ rawError: true })
  public isFeatureCred(feature: CredentialType): boolean | null {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    if (this.loggedInUser.entitlements) {
      for (
        let index = 0;
        index < this.loggedInUser.entitlements.length;
        index++
      ) {
        const element: ChangableEntitlement = this.loggedInUser.entitlements[
          index
        ];
        if (element && element.type == feature && element.active) {
          return true;
        }
      }
    }

    return false;
  }

  @Action({ rawError: true })
  public async isCredContextBased(requestObj: any): Promise<boolean | null> {
    if (!this.loggedInUser || !this.loggedInUser.roleLevel) {
      return false;
    }

    try {
      const result = await isCred(requestObj);
      return result;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateUserPreferredLanguage(
    userPreferredLanguage: string
  ): Promise<any> {
    try {
      return await updateUserPreferredLanguage(userPreferredLanguage);
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetUserPreferredLanguage(): Promise<User> {
    try {
      return await getUserPreferredLanguage();
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetInfoNote(): Promise<InfoNoteDto> {
    try {
      return await getInfoNote();
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doUpdateInfoNote(): Promise<any> {
    try {
      return updateInfoNote();
    } catch (error) {
      throw new Error(error as string);
    }
  }

  get loggedInUser(): User | null {
    try {
      if (this.user) {
        return this.user;
      }
      if (SessionStorage.getItem('app-user')) {
        return JSON.parse(SessionStorage.getItem('app-user') as string) as User;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  get userPreferredLanguageCode(): string | null {
    try {
      if (this.user && this.user.preferredLanguageCode) {
        return this.user.preferredLanguageCode;
      }
      if (SessionStorage.getItem('app-user')) {
        const user = JSON.parse(
          SessionStorage.getItem('app-user') as string
        ) as User;
        return user.preferredLanguageCode ? user.preferredLanguageCode : null;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  get userPreferredChatLanguageCode(): string | null {
    try {
      if (this.user && this.user.preferredChatLanguageCode) {
        return this.user.preferredChatLanguageCode;
      }
      if (SessionStorage.getItem('app-user')) {
        const user = JSON.parse(
          SessionStorage.getItem('app-user') as string
        ) as User;
        return user.preferredChatLanguageCode
          ? user.preferredChatLanguageCode
          : null;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  @Action({ rawError: true })
  public async doGetRemoteSystemTypes(): Promise<ILabelValue[]> {
    try {
      return await getRemoteSystemTypes();
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async doGetRemoteSystem(): Promise<RemoteSystemResponseDto> {
    try {
      const apiResponse = await getRemoteSystem();
      return apiResponse;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async updateUserTfa(user: User) {
    try {
      const apiResponse = await doUpdateUserTfa(user);
      return apiResponse;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async isViberEnabled() {
    try {
      if (this.user && this.user.entitlements) {
        const entm = this.user.entitlements.find(
          element => element.type === 'VIBER'
        );
        if (entm && entm.active) {
          return true;
        }
      }
    } catch (error) {
      throw new Error(error as string);
    }
    return false;
  }

  @Action({ rawError: true })
  public async sendTFACode() {
    try {
      const apiResponse = await doSendTFACode();
      return apiResponse;
    } catch (error) {
      throw new Error(error as string);
    }
  }

  @Action({ rawError: true })
  public async verifyTFACode(tfaCode: string) {
    try {
      const apiResponse = await doVerifyTFACode(tfaCode);
      return apiResponse;
    } catch (error) {
      throw new Error(error as string);
    }
  }
}

export default getModule(UserModule);
